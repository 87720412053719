"use strict";

// const KTLayout = require("../../metronic/_theme/js/global/layout/layout");

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output js/scripts.bundle
 */

// Core Plugins
  window.KTUtil = require("metronic/_theme/js/global/components/base/util");
  window.KTApp = require("metronic/_theme/js/global/components/base/app");
  window.KTAvatar = require("metronic/_theme/js/global/components/base/avatar");
  window.KTDialog = require("metronic/_theme/js/global/components/base/dialog");
  window.KTHeader = require("metronic/_theme/js/global/components/base/header");
  window.KTMenu = require("metronic/_theme/js/global/components/base/menu");
  window.KTOffcanvas = require("metronic/_theme/js/global/components/base/offcanvas");
  window.KTPortlet = require("metronic/_theme/js/global/components/base/portlet");
  window.KTScrolltop = require("metronic/_theme/js/global/components/base/scrolltop");
  window.KTToggle = require("metronic/_theme/js/global/components/base/toggle");
  window.KTWizard = require("metronic/_theme/js/global/components/base/wizard");

  // Layout Scripts
  window.KTLayout = require("metronic/_theme/js/global/layout/layout");
  window.KTChat = require("metronic/_theme/js/global/layout/chat");
  require("metronic/_theme/js/global/layout/demo-panel");
  require("metronic/_theme/js/global/layout/offcanvas-panel");
  require("metronic/_theme/js/global/layout/quick-panel");
  require("metronic/_theme/js/global/layout/quick-search");

  require.context('metronic/_theme/media', true);

  require("metronic/_theme/js/pages/custom/todo/todo");

document.addEventListener("turbo:load", function() {
  if(window.KTUtil != undefined){
    window.KTUtil.init()
    window.KTLayout.init()
    window.KTApp.init(KTAppOptions);
  }
})
